.link-NavBar {
  display: flex;
  align-items: center;
  height: 80px;
  color: white;
  opacity: 1;
}

.NavBar-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pre-header {
  width: 100%;
  max-width: 1920px;
  margin:auto;
  height: 30px;
  background-color: #1d160f;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  position: fixed;
  color: white;
}

.contact-display {
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
.navbar-bar {
  display: flex;
  justify-content: flex-end;
  font-size: 32px;
  padding-right: 20px;
  padding-top: 10px;
}
.navbar-mobile {
  display: flex;
  flex-direction: column;
  display: none;
  height: 50px;
  position: absolute;
  top: 30px;
  z-index: 900;
  left: 0;
  right: 0;
  background-color: #bcaa7a;
  /* opacity: 0.8; */
}

.contact-anchor-link {
  text-decoration: none;
  color: white;
}
.contact-field-container {
  height: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.navbar-mobile-content-container {
  width: 100%;
  background-color: #bcaa7a;
  /* height: ; */
}

@media screen and (max-width: 700px) {
  .navbar-mobile {
    display: block;
  }
  .link-NavBar {
    height: 40px;
  }
  .hide-on-mobile{
    display:none;
  }
}

@media  screen and (max-width:1200px) {
  .contact-display{
    width:100%;
    justify-content: center;
  }
  .pre-header{
    font-size:12px;
  }
  
}

@media screen and (max-width:500px){
  .pre-header{
    font-size:10px;

  }
  .contact-display{
    gap:5px;
  }
}