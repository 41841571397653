.form-container {
  width: 60%;
  margin: auto;

}
.name-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top:40px;
}

.phone-container {
  padding: 20px 40px;
  width: 55%;
}

.email-container {
  padding: 20px 40px;
  width: 55%;
}

.form-content-2{
    padding:40px;   
    /* width:55%; */
}
.date-label{
   display: block;
   padding:10px 0px;
}

.date-container{
    display:flex;
    gap:20px;
}
.submit-btn-container{
   width:400px;
    margin:auto;
}

.num-of-rooms-container{
  width:49%
}

.type-of-room-container{
  width:55%;
  padding:20px;
  padding-left: 0;
}
@media screen and (max-width:1000px){
  .form-container{
    width:90%;
  }
}

@media screen and (max-width:700px){
  .name-container,.date-container{
    flex-direction:column;
  }
  .phone-container{
    width:100%;
    padding: 0px 20px;

  }
  .email-container{
    width:100%;
    padding: 20px;
  }

  .num-of-rooms-container{
    width:100%;
  }
  .submit-btn-container{
    width:300px;
  }
  .type-of-room-container{
    width:100%;
  }
  .form-content-2{
    padding:20px;
  }
}