.gallery-container {
  margin-top: 80px;
}

.gallery-image {
  height: 240px;
  width: 400px;
  object-fit: cover;
}
.last-row-image-container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  /* width:100%; */
}
.modal-image {
  width: 100%;
  height: 100%;
}

.prev-button-image-modal {
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #adadad;
  outline: none;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.next-button-image-modal {
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 0px;
  background-color: #adadad;
  outline: none;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}


@media screen and (max-width:1000px){
.css-1wtl9p1{
width:80% !important;
height:60% !important;
}
.gallery-image{
  width:290px;
  height:160px;
}
}
@media screen and (max-width:700px){
.css-1wtl9p1{
width:80% !important;
height:40% !important;
}
.gallery-image{
  width:200px;
  height:120px;
}
}