.heading-container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.room-image-rooms {
  height: 600px;
  width: 900px;
}

.carousel.carousel-slider .control-arrow {
  top: 46%;
  bottom: 46%;
  background: rgba(0, 0, 0, 0.8);
}

.carousel-container {
  /* padding: 40px; */
  width: 60%;
  margin: auto;
}
.rooms-page-heading {
  font-size: 50px;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1000px) {
  .heading-container {
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  .rooms-page-heading {
    font-size: 30px;
  }
  .heading-container {
    font-size: 25px;
  }
  .room-image-rooms {
    height: 320px;
  }
  .carousel.carousel-slider .control-arrow {
    bottom: 38%;
  }
.carousel .slide img {
    width:100% !important;
}

}

@media screen and (max-width:700px){
.carousel-container{
    width:100% !important;
}
}