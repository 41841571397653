@import url(https://fonts.googleapis.com/css2?family=Goldman&family=Play&family=Playfair+Display:wght@500&family=Saira+Semi+Condensed:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing:border-box;
    font-family: 'Play', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  text-decoration: none;
}
.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.link-NavBar {
  display: flex;
  align-items: center;
  height: 80px;
  color: white;
  opacity: 1;
}

.NavBar-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pre-header {
  width: 100%;
  max-width: 1920px;
  margin:auto;
  height: 30px;
  background-color: #1d160f;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  position: fixed;
  color: white;
}

.contact-display {
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.navbar-bar {
  display: flex;
  justify-content: flex-end;
  font-size: 32px;
  padding-right: 20px;
  padding-top: 10px;
}
.navbar-mobile {
  display: flex;
  flex-direction: column;
  display: none;
  height: 50px;
  position: absolute;
  top: 30px;
  z-index: 900;
  left: 0;
  right: 0;
  background-color: #bcaa7a;
  /* opacity: 0.8; */
}

.contact-anchor-link {
  text-decoration: none;
  color: white;
}
.contact-field-container {
  height: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.navbar-mobile-content-container {
  width: 100%;
  background-color: #bcaa7a;
  /* height: ; */
}

@media screen and (max-width: 700px) {
  .navbar-mobile {
    display: block;
  }
  .link-NavBar {
    height: 40px;
  }
  .hide-on-mobile{
    display:none;
  }
}

@media  screen and (max-width:1200px) {
  .contact-display{
    width:100%;
    justify-content: center;
  }
  .pre-header{
    font-size:12px;
  }
  
}

@media screen and (max-width:500px){
  .pre-header{
    font-size:10px;

  }
  .contact-display{
    grid-gap:5px;
    gap:5px;
  }
}
.form-container {
  width: 60%;
  margin: auto;

}
.name-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding-top:40px;
}

.phone-container {
  padding: 20px 40px;
  width: 55%;
}

.email-container {
  padding: 20px 40px;
  width: 55%;
}

.form-content-2{
    padding:40px;   
    /* width:55%; */
}
.date-label{
   display: block;
   padding:10px 0px;
}

.date-container{
    display:flex;
    grid-gap:20px;
    gap:20px;
}
.submit-btn-container{
   width:400px;
    margin:auto;
}

.num-of-rooms-container{
  width:49%
}

.type-of-room-container{
  width:55%;
  padding:20px;
  padding-left: 0;
}
@media screen and (max-width:1000px){
  .form-container{
    width:90%;
  }
}

@media screen and (max-width:700px){
  .name-container,.date-container{
    flex-direction:column;
  }
  .phone-container{
    width:100%;
    padding: 0px 20px;

  }
  .email-container{
    width:100%;
    padding: 20px;
  }

  .num-of-rooms-container{
    width:100%;
  }
  .submit-btn-container{
    width:300px;
  }
  .type-of-room-container{
    width:100%;
  }
  .form-content-2{
    padding:20px;
  }
}
.custom-link{
    display: block;
    text-decoration: none;
    flex:0.5 1 !important;

   
    /* width:50%; */
}

 @media screen and (max-width:600px)  {
     .custom-link{
         flex:1 1 !important;
         
     }
        
    }
.carousel-image {
  height: 800px;
}

.welcome-top {
  text-align: end;
}

.welcome-bottom {
  text-align: end;
}
.stars-container {
  text-align: end;
}

.template-image {
  height: 240px;
  width: 400px;
  cursor: pointer;
}

.images-container:hover > .text-underline {
  transition: width 0.5s;
  width: 50%;
}

.static-image {
  cursor: pointer;
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.static-image:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.paragraph-containers {
  padding: 20px;
  padding-top: 0px;
}

.icon-container {
  display: block;
  width: 80px;
}
.book-your-room-btn-container {
  display: flex;
  /* justify-content:center;
align-items: center; */
}
.book-your-room-button {
  width: 200px !important;
  border: none !important;
  color: black !important;
  font-weight: bolder !important;
  background-color: #f7f5be !important;
  margin: auto !important;
  /* margin-top: 40px !important; */
}

.services-top {
width:100%;
text-align: end;

}

.services-bottom {
  width:100%;
text-align: end;

}
.heading{
  font-weight:bold;
  font-size: 20px;
  padding-top:10px;
  padding-bottom:10px;
}

@media screen and (max-width: 1000px) {
  .carousel-image {
    height: 600px;
  }
}

@media screen and (max-width: 700px) {
  .carousel-image {
    height: 460px;
  }

  .template-image{
    height:auto;
    width:320px;
  }

  .welcome-bottom {
    text-align: center;
  }

  .welcome-top {
    text-align: center;
  }

  .services-bottom,.services-top{
    text-align: center;
  }

  .stars-container {
    text-align: center;
    margin: 20px;
  }

  .static-image {
    width: 70%;
    height: 70%;
  }
}

.booking-hero-image {
  width: 100%;
  height: 100%;
}

.booking-heading {
  text-align: end;
}

.booking-heading-top {
  text-align: end;
}

.booking-heading-bottom {
  text-align: end;
}

.address-loc-container {
  padding: 40px;
}

.address-line-1 {
  text-align: start;
}

.address-line-2 {
  text-align: start;
}
.address-line-3 {
  text-align: start;
}

.contact-info-container {
  padding: 20px 40px;
}
.contact-heading {
  text-align: start;
}

.contact-number {
  text-align: start;
}

.email-info-container {
  padding: 20px 40px;
}
.email-heading {
  text-align: start;
}
.email-value {
  text-align: start;
}
@media screen and (max-width: 1000px) {
  .booking-heading {
    text-align: center;
  }
  .booking-heading-top {
    text-align: center;
  }
  .booking-heading-bottom {
    text-align: center;
  }
  .address-line-1,
  .address-line-2,
  .address-line-3 {
    text-align: center;
  }
  .contact-heading,
  .contact-number,.email-heading,.email-value {
    text-align: center;
  }
  .contact-info-container,.email-info-container{
      padding: 20px;
  }
  .contact-number,.email-value{
      font-size: 16px;
      line-height: 200%;
  }
}

.heading-about-our-hotel{
    text-align:end;
}

@media  screen and (max-width:700px) {
    .heading-about-our-hotel{
        text-align: center;
    }
    
}
.heading-container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.room-image-rooms {
  height: 600px;
  width: 900px;
}

.carousel.carousel-slider .control-arrow {
  top: 46%;
  bottom: 46%;
  background: rgba(0, 0, 0, 0.8);
}

.carousel-container {
  /* padding: 40px; */
  width: 60%;
  margin: auto;
}
.rooms-page-heading {
  font-size: 50px;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1000px) {
  .heading-container {
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  .rooms-page-heading {
    font-size: 30px;
  }
  .heading-container {
    font-size: 25px;
  }
  .room-image-rooms {
    height: 320px;
  }
  .carousel.carousel-slider .control-arrow {
    bottom: 38%;
  }
.carousel .slide img {
    width:100% !important;
}

}

@media screen and (max-width:700px){
.carousel-container{
    width:100% !important;
}
}
.loc-heading {
  text-align: center;
  padding-bottom: 10px;
}

.sight-image {
  width: 500px;
  height: 340px;
}

@media screen and (max-width: 1000px) {
  .sight-image {
    width: 390px;
    height: 270px;
  }


}

@media screen and (max-width:450px){
    .sight-image{
        width:320px;
        height:220px;
    }
}
.gallery-container {
  margin-top: 80px;
}

.gallery-image {
  height: 240px;
  width: 400px;
  object-fit: cover;
}
.last-row-image-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: flex-start;
  /* width:100%; */
}
.modal-image {
  width: 100%;
  height: 100%;
}

.prev-button-image-modal {
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #adadad;
  outline: none;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.next-button-image-modal {
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 0px;
  background-color: #adadad;
  outline: none;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}


@media screen and (max-width:1000px){
.css-1wtl9p1{
width:80% !important;
height:60% !important;
}
.gallery-image{
  width:290px;
  height:160px;
}
}
@media screen and (max-width:700px){
.css-1wtl9p1{
width:80% !important;
height:40% !important;
}
.gallery-image{
  width:200px;
  height:120px;
}
}
